@keyframes sign-rotate {
  from {
    transform: rotate(-60deg);
  }
  to {
    transform: rotate(60deg);
  }
}

.sign {
  animation-name: sign-rotate;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 2s;
  transform-origin: center 0;
  animation-timing-function: ease-in-out;
  width: 500px;
  position: fixed;
  left: 50%;
  margin-left: -250px;
  pointer-events: none;
}

.info {
  background-color: #000;
  position: fixed;
  bottom: 0;
  color: #fff;
  padding: 10px;
  font-size: 18px;
}

.info a {
  color: #fff;
}

.background {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  transition: 0.1s ease background-image;
  background-position: center center;
}
